p {
  padding: 0;
  margin: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.header .logoImg {
  width: 186px;
}

.header .closeBtn {
  width: 14px;
  cursor: pointer;
}

.titleBox {
  text-align: center;
  margin-bottom: 90px;
}

.titleBox h1 {
  font-family: "Bold";
  font-weight: normal;
  font-size: 26px;
  line-height: 38px;
  margin: 44px auto 16px;
}

.titleBox h2 {
  font-family: "Regular";
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #6e6e6e;
  margin: 0 auto 0;
}

.subTitle {
  font-family: "Medium";
  font-weight: 500;
  font-size: 15px;
  margin: 0 0 10px 0;
}

.input {
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding: 6px 0;
  outline: none;
  border: none;
  border-bottom: 1px solid #dfdfdf;
  font-family: "Regular";
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input&::placeholder {
  color: #bdbdbd;
  font-family: "light";
}

.input::-webkit-input-placeholder {
  color: #bdbdbd;
  font-family: "light";
}

.input&:focus {
  border-color: #2276dc;
}

.input.errInput&:focus {
  border-color: rgba(200, 59, 56, 1);
}

.orangeButton {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ed893e;
  border-radius: 43px;
  border: none;
  width: 204px;
  height: 52px;
  color: #fff;
  font-size: 17px;
  line-height: 25px;
  font-family: "Medium";
  cursor: pointer;
}

.orangeButton:disabled {
  background-color: #d9d9d9;
  cursor: auto;
}

.blueButton {
  padding: 0;
  width: 204px;
  height: 52px;
  background-color: #20315b;
  color: #fff;
  border-radius: 3000px;
  box-sizing: border-box;
  font-size: 17px;
  line-height: 25px;
  font-family: "Medium";
  cursor: pointer;
}

.blackLineButton {
  padding: 0;
  width: 204px;
  height: 52px;
  color: #000;
  border: 1px solid #000000;
  background-color: #fff;
  border-radius: 3000px;
  box-sizing: border-box;
  font-size: 17px;
  line-height: 25px;
  font-family: "Medium";
  cursor: pointer;
}

.errorMessage {
  color: rgba(200, 59, 56, 1);
  font-size: 12px;
  line-height: 18px;
  font-family: "Regular";
  padding-top: 5px;
}

.display {
  display: none;
}

.dbDataText {
  font-size: 16px;
  line-height: 24px;
  font-family: "light";
  color: #2276dc;
  padding: 6px 0 7px;
}

/*테블릿 사이즈 아래로*/
@media screen and (max-width: 1139px) {
  .header {
    width: 100%;
    min-height: 60px;
    padding: 0;
  }

  .titleBox {
    margin-bottom: 44px;
  }

  .titleBox h1 {
    font-size: 22px;
    line-height: 33px;
    padding: 22px 0 15px 0;
    margin: 8px auto 0;
  }

  .titleBox h2 {
    font-size: 15px;
    line-height: 22px;
  }

  .subTitle {
    margin: 0;
    height: 18px;
    font-size: 13px;
    line-height: 19px;
    padding: 14px 0 5px 0;
  }

  .input {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    font-family: "light";
    height: 24px;
  }

  .input:not(.dateInput) {
    padding: 18px 24px 18px 0;
    box-sizing: border-box;
  }

  .display {
    display: block;
  }

  .orangeButton {
    height: 42px;
    min-height: 42px;
    color: #fff;
    font-size: 14px;
    line-height: 21px;
  }
}
